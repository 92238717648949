import React from 'react';

// Style
import { colors, fonts } from '../../config/style';

interface SubtitleProps {
	subtitle: string;
	color?: string;
	style?: object;
	preset?: string;
	size?: number;
	weight?: number;
}

export const Subtitle = (props: SubtitleProps) => {
	let { subtitle, color, style, preset, size, weight } = props;
	return (
		<h2
			style={{
				marginBottom: 0,
				color: color || colors.text,
				fontSize: size || preset === 'page-subtitle' ? 16 : fonts.subtitleFontSize,
				fontFamily: fonts.subtitleFontFamily,
				fontWeight: weight || 400,
				lineHeight: '26px',
				...style
			}}
		>
			{subtitle}
		</h2>
	);
};
