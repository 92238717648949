import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { imageApi } from "../../config/apiConfig";
import { history, useImageStore } from "../../stores";
import type { ColumnsType } from "antd/es/table";
import { Header } from "../../components";

// Components
import { Subtitle } from "../../components";
import {
  Input,
  Upload,
  message,
  Space,
  Table,
  Tag,
  Modal,
  InputNumber,
  Button,
} from "antd";
import { getLocalStorage } from "../../utils";
const { Search } = Input;
const { Dragger } = Upload;

const renderChild = ({
  currentImageList,
  editFormOpen,
  setEditFormOpen,
  editImage,
  setEditImage,
  uploadModal,
  setUploadModal,
  searchImageModal,
  setSearchImageModal,
  getImageList,
  handleSearch,
  loading,
  setLoading,
  setCurrentImageList,
  finishedEdit,
  handleDeletion,
  updateNeedUpdates,
}: any) => {
  // for upload file

  const props = {
    name: "file",
    multiple: false,
    action: imageApi.upload.url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      userID: localStorage.getItem("userId") || "",
    },
    beforeUpload: (file: any) => {
      const isImage =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      if (!isImage) {
        message.error(`${file.name} is not a image file`);
      }
      return isImage || Upload.LIST_IGNORE;
    },
    onChange(info: any) {
      const { status } = info.file;

      if (status === "done") {
        message.success(
          `${info.file.name} file uploaded successfully. Please wait for the tags result`
        );
        setUploadModal(false);
        updateNeedUpdates();
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const searchProps = {
    name: "file",
    multiple: false,
    action: imageApi.imageSearch.url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      userID: localStorage.getItem("userId") || "",
    },
    beforeUpload: (file: any) => {
      const isImage =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      if (!isImage) {
        message.error(`${file.name} is not a image file`);
      }
      message.info("Starting to searching images");
      setLoading(true);

      return isImage || Upload.LIST_IGNORE;
    },
    onChange(info: any) {
      const { status, response } = info.file;
      if (status === "done") {
        const { data } = response;
        setLoading(false);
        setCurrentImageList(JSON.parse(data.body));
        setSearchImageModal(false);
        message.success(`Successfully get the image searching result`);
        setSearchImageModal(false);
      } else if (status === "error") {
        setLoading(false);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleEdit = (image: any) => {
    setEditImage(image);
    setEditFormOpen(true);
  };

  const addTags = () => {
    const { tags } = editImage;
    const updatedTags = [...tags, { "": 0 }];
    const updatedEditImage = { ...editImage, tags: updatedTags };
    setEditImage(updatedEditImage);
  };

  const handleTagNameChange = (name: string, index: number) => {
    const updatedTags = [...editImage.tags];
    const tag = updatedTags[index];
    const tagName = Object.keys(tag)[0];
    tag[name] = tag[tagName]; // Preserve the existing count
    delete tag[tagName]; // Remove the old tag name
    setEditImage({ ...editImage, tags: updatedTags });
  };

  const handleTagCountChange = (count: any, index: number) => {
    const updatedTags = [...editImage.tags];
    const tag = updatedTags[index];
    const tagName = Object.keys(tag)[0];
    tag[tagName] = parseInt(count); // Update the tag count
    setEditImage({ ...editImage, tags: updatedTags });
  };

  const deleteTags = (index: number) => {
    const updatedTags = [...editImage.tags];
    updatedTags.splice(index, 1); // Remove the tag at the specified index
    setEditImage({ ...editImage, tags: updatedTags });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Image",
      dataIndex: "link",
      key: "link",
      width: 300,
      render: (text) => <img style={{ width: 300 }} src={text} />,
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      width: 500,
      render: (_, { tags }) => (
        <>
          {tags.map((tag: any, index: number) => {
            let color = tag.length > 1 ? "geekblue" : "green";
            const name = Object.keys(tag)[0];
            return (
              <Tag color={color} key={name + index}>
                {name ? name.toUpperCase() : ""} ({tag[name]})
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => handleEdit(record)}>Edit Tags</a>
          <a onClick={() => handleDeletion(record)}>Delete</a>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      {/* Modal form */}
      <Modal
        title="Edit Tags"
        open={editFormOpen}
        onOk={finishedEdit}
        onCancel={() => setEditFormOpen(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img style={{ width: "90%" }} src={editImage ? editImage.link : ""} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
              width: "90%",
              textAlign: "center",
            }}
          >
            <p style={{ marginTop: 0, flex: 1 }}>Tag Name</p>
            <p style={{ marginTop: 0, flex: 1 }}>Counts</p>
            <p style={{ marginTop: 0, flex: 1 }}>Delete</p>
          </div>

          {editImage &&
            editImage.tags.map((tag: any, index: number) => {
              const name = Object.keys(tag)[0];

              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 20,
                    width: "90%",
                    textAlign: "center",
                  }}
                >
                  <input
                    value={name}
                    onChange={(e) => handleTagNameChange(e.target.value, index)}
                  />
                  <div style={{ flex: 1 }}>
                    <InputNumber
                      style={{ width: "50%" }}
                      defaultValue={tag[name]}
                      minLength={1}
                      onChange={(value) => handleTagCountChange(value, index)}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Button
                      onClick={() => deleteTags(index)}
                      style={{ width: "50%" }}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              );
            })}

          <Button
            type="primary"
            style={{ marginTop: 20, width: "90%" }}
            onClick={() => addTags()}
          >
            Add More Tag
          </Button>
        </div>
      </Modal>

      <Modal
        title="Upload Image"
        open={uploadModal}
        onOk={() => setUploadModal(false)}
        onCancel={() => setUploadModal(false)}
      >
        <Dragger {...props}>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p>
        </Dragger>
      </Modal>

      <Modal
        title="Image Search"
        open={searchImageModal}
        onOk={() => setSearchImageModal(false)}
        onCancel={() => setSearchImageModal(false)}
      >
        <Dragger {...searchProps}>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p>
        </Dragger>
      </Modal>

      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Subtitle subtitle="Images" preset="page-subtitle" />
        <Search
          placeholder="Search by Tag"
          onSearch={handleSearch}
          style={{ width: 200, marginLeft: 20 }}
        />

        <Button onClick={() => setUploadModal(true)} style={{ marginLeft: 20 }}>
          Upload Image
        </Button>

        <Button
          onClick={() => setSearchImageModal(true)}
          style={{ marginLeft: 20 }}
        >
          Image Search
        </Button>
      </div>

      <Table
        loading={loading}
        style={{ marginTop: 20 }}
        columns={columns}
        dataSource={currentImageList}
      />
    </div>
  );
};

const ImagePage = () => {
  const [currentImageList, setCurrentImageList] = useState();
  const [editImage, setEditImage] = useState(null);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [searchImageModal, setSearchImageModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    imageList,
    getImageList,
    deleteImage,
    updateImage,
    searchImageByTags,
    fetchNewImage,
    updateNeedUpdates,
  } = useImageStore();

  useEffect(() => {
    getImageList();
    getLocalStorage("accessToken").then((accessToken) => {
      if (!accessToken) {
        message.error("Please login first");
        history.push("/");
      }
    });

    const interval = setInterval(() => {
      fetchNewImage();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCurrentImageList(imageList);
  }, [imageList]);

  const handleSearch = async (query: string) => {
    try {
      if (query === "") {
        getImageList();
        return;
      }

      if (!query.includes(":")) {
        message.error("Query format should be tag:count,tag2:count");
        return;
      }

      setLoading(true);
      message.info("Starting to search the image");

      const tagsSearch = query.split(",");
      const transformedTags = tagsSearch.reduce((result: any, tag) => {
        const [name, count] = tag.split(":");
        result[name.toLowerCase()] = parseInt(count);
        return result;
      }, {});

      const jsonString = JSON.stringify(transformedTags);
      const data = await searchImageByTags({ tags: jsonString });
      console.log("data", data);
      setCurrentImageList(JSON.parse(data));
      message.info("Finished searching");
      setLoading(false);
    } catch (e) {
      message.error("failed to search");
      setLoading(false);
    }
  };

  const finishedEdit = async () => {
    await updateImage(editImage);
    message.info("Update Successfully");
    setEditFormOpen(false);
  };

  const handleDeletion = async (image: any) => {
    const { imageID } = image;
    await deleteImage(imageID);
    message.info("Image deleted");
  };

  let config = {
    currentImageList,
    editImage,
    setEditImage,
    deleteImage,
    editFormOpen,
    setEditFormOpen,
    updateImage,
    uploadModal,
    setUploadModal,
    getImageList,
    searchImageModal,
    setSearchImageModal,
    handleSearch,
    loading,
    setLoading,
    setCurrentImageList,
    finishedEdit,
    handleDeletion,
    updateNeedUpdates,
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        minHeight: "100vh",
      }}
    >
      {/* Header */}
      <Header />

      <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
        {/* Child Props */}
        <div style={{ padding: "40px 60px", flex: 1, display: "flex" }}>
          {renderChild(config)}
        </div>
      </div>
    </div>
  );
};

export default observer(ImagePage);
