import { action } from "mobx";
import { getLocalStorage, removeAllLocalStorage } from "../utils";
import axios, { Method } from "axios";
import { message } from "antd";
import { history } from "../stores";
export class ApiStore {
  @action.bound
  callApi(method: Method, url: string, params: any = null) {
    return new Promise(async (resolve, reject) => {
      try {
        const accessToken = await getLocalStorage("accessToken");
        const userId = await getLocalStorage("userId");
        // Redirect to register page if the user is not authenticated
        if (!accessToken) {
          removeAllLocalStorage();
          return;
        }
        axios({
          method: method,
          url: url,
          data: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            userID: userId,
          },
        })
          .then((response) => {
            resolve(response ? response.data : null);
          })
          .catch(async (error) => {
            const { status, data } = (error && error.response) || {};
            if (status === 401) {
              // need to double check here...
              message.error("Please login again");
              removeAllLocalStorage();
              return history.push("/");
            }
            reject(data);
          });
      } catch (e) {
        reject({ code: 500, message: "Please try again later", data: null });
      }
    });
  }
}

export const STORE_API = "apiStore";
