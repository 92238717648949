import moment from "moment";

const formatDateForPeriod = (date: string) => {
  return moment(date, "DD-MM-YYYY").format("MM/YYYY");
};

const formatDateForInput = (date: string) => {
  return moment(date, "DD-MM-YYYY").format("yyyy-MM-DD");
};

const formatDateTimeForInput = (date: string) => {
  return moment(date, "MM-DD-yyyy HH:mm:ss");
};

const formatMomentToString = (date: any) => {
  return moment(date, "MM-DD-YYYY HH:mm:ss").format("MM-DD-YYYY HH:mm:ss");
};

const formatDateFromInput = (date: string) => {
  return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
};

const removeAllLocalStorage = () => {
  localStorage.clear();
};

const setLocalStorage = (key: string, params: any) => {
  // check the params is object or not , if object json stringify
  if (typeof params === "object") {
    params = JSON.stringify(params);
  }
  localStorage.setItem(key, params);
  return true;
};

const getLocalStorage = (key: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const item = await localStorage.getItem(key);
      resolve(item);
    } catch (e: any) {
      reject({ code: 500, message: e.message, data: null });
    }
  });
};

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export {
  setLocalStorage,
  getLocalStorage,
  removeAllLocalStorage,
  formatDateForInput,
  formatDateFromInput,
  formatDateForPeriod,
  formatDateTimeForInput,
  formatMomentToString,
  validateEmail,
};
