import { action, observable } from "mobx";
import { message } from "antd";
import { imageApi } from "../config/apiConfig";
import { ApiStore } from "./api";

export class ImageStore {
  @observable imageList: any = [];
  @observable needUpdates: boolean = false;

  @action.bound
  async checkHealth() {
    try {
      const { url, method } = imageApi.health;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url);
      const { data, status } = result;
      this.imageList = data;
      return status;
    } catch (error: any) {
      message.error(
        error && error.message ? error.message : "Cannot get the image list"
      );
      return false;
    }
  }

  @action.bound
  async updateNeedUpdates() {
    this.needUpdates = true;
  }

  @action.bound
  async fetchNewImage() {
    try {
      const { url, method } = imageApi.list;
      // get call api method
      const { callApi } = new ApiStore();

      if (this.needUpdates) {
        const result: any = await callApi(method, url);
        const { data, status } = result;
        if (data && data.length > this.imageList.length) {
          message.info("New images is coming");
          this.imageList = data;
          this.needUpdates = false;
        }
      }

      return true;
    } catch (error: any) {
      message.error(
        error && error.message ? error.message : "Cannot get the image list"
      );
      return false;
    }
  }

  @action.bound
  async getImageList(newImage = false) {
    try {
      const { url, method } = imageApi.list;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url);
      const { data, status } = result;

      this.imageList = data;
      return status;
    } catch (error: any) {
      message.error(
        error && error.message ? error.message : "Cannot get the image list"
      );
      return false;
    }
  }

  @action.bound
  async deleteImage(imageId: string) {
    try {
      const { url, method } = imageApi.delete;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url, { imageID: imageId });
      const { data, status } = result;
      this.getImageList();
      return status;
    } catch (error: any) {
      message.error(
        error && error.message ? error.message : "Cannot delete the image"
      );
      return false;
    }
  }

  @action.bound
  async updateImage(image: any) {
    try {
      const { url, method } = imageApi.updateImage;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url, image);
      const { status } = result;
      this.getImageList();
      return status;
    } catch (error: any) {
      message.error(
        error && error.message ? error.message : "Cannot delete the image"
      );
      return false;
    }
  }

  @action.bound
  async searchImageByTags(tags: any) {
    try {
      const { url, method } = imageApi.search;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url, tags);
      const { data } = result;
      return data.body;
    } catch (error: any) {
      message.error(
        error && error.message
          ? error.message
          : "Could not do searching now, please wait for a minutes"
      );
      return false;
    }
  }
}

export const STORE_IMAGE = "imageStore";
