import React, { useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { CognitoUserAttribute, CognitoUser } from "amazon-cognito-identity-js";

// Components
import { Line, Subtitle, Form, Button } from "../../components";
import { fields } from "./fields";
import userPool from "../../utils/UserPool";
import { Input } from "antd";
// Style
import { colors } from "../../config/style";
import { message } from "antd";
import { history } from "../../stores";

const RegisterForm = () => {
  const [code, setCode] = useState("");
  const [username, setUsername] = useState("");
  const [stage, setStage] = useState(0);

  const onFinish = async (values: any) => {
    const { username, password, givenName, familyName } = values;
    setUsername(username);
    const attributeList = [];

    const dataEmail = {
      Name: "email",
      Value: username,
    };
    const dataGivenName = {
      Name: "given_name",
      Value: givenName,
    };
    const dataFamilyName = {
      Name: "family_name",
      Value: familyName,
    };

    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributeGivenName = new CognitoUserAttribute(dataGivenName);
    const attributeFamilyName = new CognitoUserAttribute(dataFamilyName);

    attributeList.push(attributeEmail);
    attributeList.push(attributeGivenName);
    attributeList.push(attributeFamilyName);

    await userPool.signUp(
      username,
      password,
      attributeList,
      attributeList,
      (err, data) => {
        if (err) {
          message.error(err.message);
        } else {
          message.info(
            "Register Successfully, please check your mail box and verify it"
          );
          setStage(1);
        }
        return;
      }
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleTwoFactor = async () => {
    const user = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    await user.confirmRegistration(code, true, (err, data) => {
      if (err) {
        message.error(err.message);
        return;
      } else {
        message.info("Code Confirmed");
        return history.push("/");
      }
    });
  };

  return (
    <div style={{ minWidth: 300 }}>
      <Subtitle subtitle={"Register"} />
      <Line position={"center"} color={colors.secondary} height={2} />
      {/* Form */}
      <div style={{ maxWidth: 400 }}>
        {stage === 0 && (
          <Form
            fields={fields}
            formName="login-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            submitBtnName="Register"
            submitBtnType="submit"
            submitBtnClass="login-button"
          />
        )}

        {stage === 1 && (
          <div>
            <Subtitle
              subtitle={"Confirm code"}
              style={{ fontSize: 15, marginBottom: 20 }}
            />
            <Input value={code} onChange={(e) => setCode(e.target.value)} />
            <Button
              style={{ marginTop: 20 }}
              buttonName="Send"
              onClick={() => handleTwoFactor()}
            />
          </div>
        )}

        {/* Login Link */}
        <Subtitle
          subtitle={"Already have an account?"}
          style={{ fontSize: 12, marginTop: 20 }}
        />
        <Link to="/">Login Now</Link>
      </div>
    </div>
  );
};

export default observer(RegisterForm);
