import React, { useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";
import { gapi } from "gapi-script";
import { observer } from "mobx-react";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import AWS from "aws-sdk";

// Components
import { Line, Subtitle, Form } from "../../components";
import { fields } from "./fields";
import userPool from "../../utils/UserPool";
import { history } from "../../stores";

// Style
import { colors } from "../../config/style";
import { message } from "antd";
import { getLocalStorage, setLocalStorage } from "../../utils";

const LoginForm = () => {
  const onFinish = async (values: any) => {
    const { username, password } = values;

    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const user = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    await user.authenticateUser(authenticationDetails, {
      onSuccess: (data) => {
        // Pass the user's access token to API Gateway
        const accessToken = data.getAccessToken().getJwtToken();
        localStorage.setItem("accessToken", accessToken);

        // Get the user's id (using sub attribute)
        user.getUserAttributes(async (err, attributes) => {
          if (err) {
            console.error("Error retrieving user attributes:", err);
            return;
          }
          if (attributes) {
            // Find the 'sub' attribute
            const userId = attributes.find(
              (attribute) => attribute.getName() === "sub"
            );

            if (userId) {
              const idValue = userId.getValue();
              // Save the user's id to local storage
              await localStorage.setItem("userId", idValue);
              history.push("/images");
            } else {
              message.error("Please try to login again");
            }
          } else {
            message.error("Please try to login again");
          }
        });
      },
      onFailure: (err) => {
        message.error(err.message);
      },
    });
  };

  const onFinishFailed = (errorInfo: any) => {};

  const responseGoogle = (response: any) => {
    if (response.error) {
      console.error("Google Sign-In failed:", response.error);
    } else {
      console.log("Google Sign-In success:", response);
      const accessToken = response.accessToken;
      // Save the token to local storage
      localStorage.setItem("accessToken", accessToken);

      // Get the user's id (googleId attribute) from the response
      const userId = response.profileObj.googleId;

      if (userId) {
        // Save the user's id to local storage
        localStorage.setItem("userId", userId);
        console.log("User id:", userId);
      } else {
        console.error("googleId attribute not found");
      }

      signinCallback(response.tokenId);

      // Redirect to "/images" after successful login
    }
  };

  const signinCallback = async (id_token: any) => {
    await AWS.config.update({
      region: "ap-southeast-2",
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: "ap-southeast-2:2d91fa46-806f-4aba-9625-ffac89aa8a74",
        Logins: {
          "accounts.google.com": id_token,
        },
      }),
    });

    AWS.config.getCredentials(async (err) => {
      if (err) {
        console.log("Cannot get the credential", err);
        console.log("Error stack:", err.stack);
      } else {
        console.log("AWS.config.credentials", AWS.config.credentials);
        await setLocalStorage(
          "accessToken",
          AWS.config.credentials?.secretAccessKey
        );
      }
    });
  };

  useEffect(() => {
    async function start() {
      const accessToken = await getLocalStorage("accessToken");

      if (accessToken) {
        message.info("You already logged in");
        history.push("/images");
      }
    }
    start();
  });

  return (
    <div style={{ minWidth: 300 }}>
      <Subtitle subtitle={"Admin Login"} />
      <Line position={"center"} color={colors.secondary} height={2} />
      {/* Form */}
      <div style={{ maxWidth: 400 }}>
        <Form
          fields={fields}
          formName="login-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          submitBtnName="ACCESS TO SYSTEM"
          submitBtnType="submit"
          submitBtnClass="login-button"
        />
      </div>
      {/* Google Login */}
      <GoogleLogin
        clientId="670355635212-tf1b046jqd6fl4skcutie9bt02sok9r0.apps.googleusercontent.com"
        buttonText="Login With Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
      {/* Register Link */}
      <div style={{ height: 20 }} />
      <Subtitle subtitle={"Don't have an account?"} style={{ fontSize: 12 }} />
      <Link to="/register">Register Now</Link>
    </div>
  );
};

export default observer(LoginForm);
