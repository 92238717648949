import React from "react";
import { Router, Switch, Route } from "react-router";
import { history } from "./stores";

// Components
import Login from "./pages/login";
import ImagePage from "./pages/images";
import RegisterPage from './pages/register'
export default function Routers() {
  return (
    <Router history={history}>
      <div
        style={{ display: "flex", flex: 1, minHeight: "100vh", minWidth: 1024 }}
      >
        <Switch>
          <Route path={"/"} exact component={Login} />
          <Route path={"/register"} exact component={RegisterPage} />
          <Route path={"/images"} exact component={ImagePage} />
        </Switch>
      </div>
    </Router>
  );
}
