import { Method } from "axios";
require("dotenv").config();

const endpoint = "https://tqvx9ww2dk.execute-api.ap-southeast-2.amazonaws.com";

const POST: Method = "POST";
const PUT: Method = "PUT";
const GET: Method = "GET";
const DELETE: Method = "DELETE";

const authApi = {
  login: {
    method: POST,
    url: `${endpoint}/login`,
  },
};

const imageApi = {
  upload: {
    method: POST,
    url: `${endpoint}/file`,
  },

  health: {
    method: GET,
    url: `${endpoint}/`,
  },
  list: {
    method: GET,
    url: `${endpoint}/file`,
  },
  delete: {
    method: DELETE,
    url: `${endpoint}/file`,
  },
  updateImage: {
    method: PUT,
    url: `${endpoint}/file`,
  },
  search: {
    method: POST,
    url: `${endpoint}/tag/search`,
  },
  imageSearch: {
    method: POST,
    url: `${endpoint}/image/search`,
  },
};

export { authApi, imageApi };
