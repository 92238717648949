// Fields for forms
const fields = [
  {
    id: 1,
    name: "Username",
    type: "text",
    rules: { required: true, message: "Please input email !" },
  },
  {
    id: 2,
    name: "Password",
    type: "password",
    rules: { required: true, message: "Please input password !" },
  },
  {
    id: 3,
    name: "Given Name",
    type: "text",
    rules: { required: true, message: "Please input given name !" },
  },
  {
    id: 4,
    name: "Family Name",
    type: "text",
    rules: { required: true, message: "Please input family name !" },
  },
];

export { fields };
